import React from 'react'
import { Root, Routes } from 'react-static'
import { Link } from '@reach/router'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

class App extends React.Component {
  render() {
    return (
      <Root>
        <Container style={{ background: 'white' }}>
          <Row>
            <Col>
              <header>
                <Navbar expand="lg">
                  <Navbar.Brand><Link to="/"><img src="/logo.png" id="logo"></img></Link></Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="ml-auto">
                      <Link className="navlink" to="/">HOME</Link>
                      <Link className="navlink" to="/process">PROCESS</Link>
                      <Link className="navlink" to="/products">PRODUCTS</Link>
                      <Link className="navlink" to="/contact">CONTACT</Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </header>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content">
                <Routes />
              </div>
            </Col>
          </Row>
          <Row>
            <footer>
              <Container className="text-center">
                <a style={{ color: "white" }} href="https://www.facebook.com/IslandMaltHouse"><i className="fa fa-facebook-square"></i> Facebook</a>
                <p>© Island Malt House {new Date().getFullYear()}</p>
              </Container>
            </footer>
          </Row>
        </Container>
      </Root>
    )
  }
}

export default App