// Imports


// Plugins
const plugins = [{
  location: "/home/vsts/work/1/s/react/islandmalthouse/node_modules/react-static-plugin-typescript",
  plugins: [],
  hooks: {}
},
{
  location: "/home/vsts/work/1/s/react/islandmalthouse",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins